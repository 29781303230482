import * as THREE from 'three'
import React, { useRef, Suspense, useMemo, useState } from 'react'
import { Canvas, useFrame, useLoader, useUpdate } from 'react-three-fiber'

function Text({
  children,
  vAlign = "center",
  hAlign = "center",
  size = 1,
  color = "#000000",
  ...props
}) {
  const font = useLoader(THREE.FontLoader, "/bold.blob")
  const config = useMemo(
    () => ({
      font,
      size: 40,
      height: 30,
      curveSegments: 32,
      bevelEnabled: true,
      bevelThickness: 6,
      bevelSize: 2.5,
      bevelOffset: 0,
      bevelSegments: 8,
    }),
    [font]
  )
  const mesh = useUpdate(
    self => {
      const size = new THREE.Vector3()
      self.geometry.computeBoundingBox()
      self.geometry.boundingBox.getSize(size)
      self.position.x =
        hAlign === "center" ? -size.x / 2 : hAlign === "right" ? 0 : -size.x
      self.position.y =
        vAlign === "center" ? -size.y / 2 : vAlign === "top" ? 0 : -size.y
    },
    [children]
  )
  const [hovered, setHover] = useState(false)
  return (
    <group {...props} scale={hovered ? [0.11 * size, 0.11 * size, 0.11] : [0.1 * size, 0.1 * size, 0.1]}
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}>
      <mesh ref={mesh}>
        <textGeometry attach="geometry" args={[children, config]} />
        <meshNormalMaterial attach="material"/>
      </mesh>
    </group>
  )
}

function Jumbo() {
  const ref = useRef()
  useFrame(({ clock }) => (
      ref.current.rotation.x = 
      ref.current.rotation.y = 
      ref.current.rotation.z = 
      Math.sin(clock.getElapsedTime()) * 0.3))
  return (
    <group ref={ref}>
      <Text hAlign="left" position={[-2.2, 0, 0]} children="RPR" />
      <Text hAlign="left" position={[2.5, -0.8, 0]} children="X" size={0.7} />
      <Text hAlign="left" position={[12.2, 0, 0]} children="TRI" />
    </group>
  )
}

export default ({}) => {
  return (
    <Canvas style={{backgroundColor:"black", minHeight:'200px'}}
        camera={{position:[0, 0, 10]}}>
      <ambientLight intensity={2} />
      <pointLight position={[20, 20, 20]} />
      <Suspense fallback={null}>
        <Jumbo />
      </Suspense>
    </Canvas>
  )
}