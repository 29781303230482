import React from 'react'
import SEO from '../components/SEO/seo'
import Layout from "../components/layout"
import Article from "../components/article"
import RPRDemo from "../components/rprdemo"

export default () => {
    return (
      <Layout>
        <SEO title="Tri Chu - Rocket Paper Reality Demo" description=""></SEO>
        <Article>
          <h2>Hi Rock Paper Reality,</h2>
          <p>
            My name is Tri Chu and I am a seasoned Full-stack Software Engineer
            with a long love for XR, gaming and everything graphics. I saw that
            you are looking for a WebAR Developer and think we are a match. Let
            me explain why.
          </p>
          <h2>Tri - the responsible professional developer</h2>
          <p>
            You are looking for someone who can{" "}
            <b>
              design, implement, test, deploy, maintain and optimize Web AR
              components and projects while
            </b>
            <b>
              participating in architecture discussions, lead product design,
              code reviews and propose solutions to system and product changes
            </b>
            . At my current role at{" "}
            <a href="https://www.hvst.com/">Harvest Exchange</a>, I have
            designed, implemented, tested, deployed, maintained and optimized a
            web application to track, introduce and connect thousands of
            financial actors. This system is utilized by major financial firms
            (e.g. Fidelity) to publish specialized financial contents to their
            world-wide customers and gaining new ones. To keep the system
            running optimally 24/7, I have honed a specific set of skills in
            implementing REST-ful <b>Python</b> server architecture running on{" "}
            <b>AWS</b>
            while continuously integrate and deploy (<b>CI/CD</b>) and maintain
            a modern well-designed web application in{" "}
            <b>Javascript, HTML/CSS</b>. Our web front end was built using{" "}
            <b>React</b>. I have touched all parts of the code from{" "}
            <b>event loop</b> to <b>asynchronous request handling</b> and{" "}
            <b>DOM</b> to building and optimizing architectures on AWS <b>S3</b>{" "}
            and <b>EC2</b>. I have been instrumental in HVST’s efforts to
            modernize its stack from Ruby on Rails to metal hardware Python
            server to AWS hardware to optimize for cost, efficiency and
            development friendliness.
          </p>
          <h2>Tri - the perpetual optimizer</h2>
          <p>
            In my 8+ years of professional experience, I am always in a constant
            search to improve. At my previous role as a Scientific Software
            Engineer at Baylor College of Medicine, I helped improve the
            performance of their <b>SQL</b> queries to speed up their reporting
            process by 15x. I have developed a keen awareness of the sometimes
            conflicting priorities between reducing technical debt to maintain a
            <b>focused and productive environment</b> while still{" "}
            <b>delivering on promised dates.</b>
          </p>
          <p>
            I can work <b>autonomously</b> and I always{" "}
            <b>welcome constructive feedback.</b>
            In my early years, I was a team member in our university gaming
            developer competition in{" "}
            <a href="https://imaginecup.microsoft.com/en-us/Events?id=0">
              {" "}
              Microsoft Imagine Cup{" "}
            </a>
            , I had a blast building the games and experience with Microsoft XNA
            (a gaming <b>computer graphics frameworks</b>). But the most
            important skills I have gained were how to{" "}
            <b>prototype, iterate and implement challenges as they arise</b>{" "}
            while being mindful of whether or when to de-escalate potential
            interpersonal issues. I have been improving on such skills as my
            responsibilities grow over my career. But the optimization is a
            journey, not a goal, this is where Rock Paper Reality comes in.
          </p>
          <h2>Tri - the scissor you have been looking for</h2>
          <p style={{ fontSize: ".8em", lineHeight: ".9em" }}>
            (unless you are not into scissors then I can be the Lizard or Spock
            as well <span role="img" aria-label="smiling face">&#x1F642;</span> )
          </p>
          <p>
            Rock Paper Reality has a formidable leadership team. Between Bobby,
            Patrick and Preston, there are 30+ human years of immersive
            experiences. I am excited to be part of a team with such a strong
            presence and record in the field. I believe that COVID 19 is the
            black swan that is going to accelerate XR into the primary (if not
            the only) medium of distribution for content, ads, messaging, gaming
            and retail. I want to be part of that adventure.
          </p>
          <p>
            In conclusion, I believe I have the skill set, the passion and the
            fit to be part of RPR’s immersive future. I am looking forward to
            speaking to RPR about that future.
          </p>
          <div id="rpr_demo_container">
            <RPRDemo></RPRDemo>
          </div>
        </Article>
      </Layout>
    )
}